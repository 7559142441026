import React from 'react';
import './Navigation.scss';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faUserCircle,
	faCog,
	faUsers,
	faExchangeAlt,
	faSignOutAlt,
	faPhone,
	faEnvelope,
	faCommentAlt,
	faInfoCircle,
	faColumns,
	faGift,
	faShoppingBasket,
	faTruck,
	faStar,
	faReceipt,
	faChartBar,
	faBullseye,
	faVial,
	faTag,
	faLanguage,
	faTasks,
	faUserShield,
	faFile
} from '@fortawesome/free-solid-svg-icons';
//import { ReactComponent as CharlesLogo } from './../../assets/CharlesLogo.svg';

import CharlesLogo from './../CharlesLogo';

const ICONS = {
	'fas fa-columns': faColumns,
	'fas fa-gift': faGift,
	'fas fa-shopping-basket': faShoppingBasket,
	'fas fa-truck': faTruck,
	'fas fa-star': faStar,
	'fas fa-receipt': faReceipt,
	'fas fa-chart-bar': faChartBar,
	'fas fa-bullseye': faBullseye,
	'fas fa-vial': faVial,
	'fas fa-tag': faTag,
	'fas fa-language': faLanguage,
	'fas fa-tasks': faTasks,
	'fas fa-user-shield': faUserShield,
	'fas fa-file': faFile
};

function Navigation(props) {
	const location = useLocation();
	const { t } = props;

	const isActive = (path, match, location) => {
		if (location.pathname.startsWith('/order/40-48') && path !== '/order/40-48') return false;
		return !!(match || location.pathname.startsWith(path));
	};

	const closeNav = () => {
		const isMobile = window.innerWidth <= 650;
		if (isMobile) props.toggleCollapse(true);
	};

	const renderHeader = () => {
		return (
			<div className={'nav__brand'}>
				<div className={'spacer'}/>

				<a className={'brand__image'} href={props.portalBaseUrl}>
					<CharlesLogo />
				</a>

				<span className={'nav__icon nav__collapse'} onClick={props.toggleCollapse}>
					{props.collapsed ? (
						<FontAwesomeIcon icon={faAngleDoubleRight} />
					) : (
						<FontAwesomeIcon icon={faAngleDoubleLeft} />
					)}
				</span>

				<div className='hamburger-menu' onClick={props.toggleCollapse}>
					<span className='line--1'/>
					<span className='line--2'/>
					<span className='line--3'/>
				</div>
			</div>
		);
	};

	const renderUser = () => {


		return (
			<div className={'nav__user'}>
				<a className={'user__item user__login'} href={`${props.portalBaseUrl}/#klant/gegevens`}>
					<div className={'item__description'}>
						<div className={'decsription__icon'}>
							<FontAwesomeIcon icon={faUserCircle} />
						</div>
						{props.username}
					</div>
					<div className={'nav__icon'}>
						<FontAwesomeIcon icon={faCog} />
					</div>
				</a>

				{props.customers?.length > 1 && (
					<a className={'user__item user__customer'} href={`${props.portalBaseUrl}/#klant/selectie_nr`}>
						<div className={'item__description'}>
							<div className={'decsription__icon'}>
								<FontAwesomeIcon icon={faUsers} />
							</div>
							{props.customer.code} {props.customer.name}
						</div>
						<div className={'nav__icon'}>
							<FontAwesomeIcon icon={faExchangeAlt} />
						</div>
					</a>
				)}
				{props.suppliers?.length > 1 && (
					<NavLink className={'user__item user__customer'} to={`/suppliers?from=${location.pathname}`}>
						<div className={'item__description'}>
							<div className={'decsription__icon'}>
								<FontAwesomeIcon icon={faUsers} />
							</div>
							{props.supplier?.code} {props.supplier?.name}
						</div>
						{props.suppliers?.length > 1 && (
							<div className={'nav__icon'}>
								<FontAwesomeIcon icon={faExchangeAlt} />
							</div>
						)}
					</NavLink>
				)}
				{renderLogout()}

			</div>
		);
	};

	const renderLogout = () => {
		if (props.renderLogout) return props.renderLogout();
		return (
			<a className={'user__item user__logout'} href={`${props.portalBaseUrl}/index.php/login/logout`}>
				<div className={'item__description'}>
					{t('nav.logout')}
				</div>
				<div className={'nav__icon'}>
					<FontAwesomeIcon icon={faSignOutAlt} />
				</div>
			</a>
		);
	};

	const renderNavItemIcon = icon => {
		if (icon.split(' ')[0] === 'fas') {
			if(!ICONS[icon]) return null;
			return (
				<FontAwesomeIcon icon={ICONS[icon]} />
			);
		} else if (icon.split(' ')[0] === 'custom') {
			const name = icon.split('custom-')[1];
			switch (name) {
				/*
				case 'thisCustomName':
					return (
						<img src={CustomImportedImageSource} alt={'Custom and descriptive name'} />
					);
				 */
				default:
					return null;
			}
		} else {
			return null;
		}
	};

	const renderNavItem = item => {
		if (!item.href.includes(window.location.hostname)) {
			return (
				<li className={'nav__item'} key={item.title}>
					<a href={item.href} className={'nav__link'}>
						<div className={'item__description'}>
							{item.title}
						</div>
						<div className={'nav__icon'}>
							{renderNavItemIcon(item.icon)}
						</div>
					</a>
				</li>
			);
		}

		return (
			<li className={'nav__item'} key={item.title}>
				<NavLink
					to={new URL(item.href).pathname}
					className={'nav__link'}
					activeClassName={'nav__link--active'}
					isActive={(match, location) => isActive(item.href, match, location)}
					onClick={closeNav}
				>
					<div className={'item__description'}>
						{item.title}
					</div>
					<div className={'nav__icon'}>
						{renderNavItemIcon(item.icon)}
					</div>
				</NavLink>
			</li>
		);
	};

	const renderNavItems = navItems => {
		if (!navItems) return null;

		return (
			<ul className={'nav__items'}>
				{navItems.map(renderNavItem)}
			</ul>
		);
	};

	const renderContact = () => {
		return (
			<div className={'nav__contact'}>
				<div className={'contact__address'}>
					{props.company.name}<br />
					{props.company['address.street']}<br />
					{props.company['address.postalcode']} {props.company['address.city']}<br />
				</div>

				<div className={'contact__details'}>
					{props?.company?.phone && (
						<div className={'detail__telephone'}>
							<div className={'contact__icon'}>
								<FontAwesomeIcon icon={faPhone} />
							</div>
							{props.company.phone}
						</div>
					)}

					{props?.company?.email && (
						<a className={'detail__email'} href={`mailto:${props.company.email}`}>
							<div className={'contact__icon'}>
								<FontAwesomeIcon icon={faEnvelope} />
							</div>
							{props.company.email}
						</a>
					)}

					{props.versionNumber && (
						<div className={'detail__version'}>
							<div className={'contact__icon'}>
								<FontAwesomeIcon icon={faInfoCircle} />
							</div>
							{props.versionNumber}
						</div>
					)}

					<a className={'detail__message'} href={`${props.portalBaseUrl}#klant/contact`}>
						<div className={'contact__icon'}>
							<FontAwesomeIcon icon={faCommentAlt} />
						</div>
						{t('nav.contact')}
					</a>
				</div>
			</div>
		);
	};

	const renderTerms = () => (
		<div className={'nav__terms'}>
			<NavLink
				to='/disclaimer'
				className={'terms__link'}
				activeClassName={'terms__link--active'}
				isActive={(match, location) => isActive('/disclaimer', match, location)}
				onClick={closeNav}
			>
				{t('nav.terms')}
			</NavLink>
		</div>
	);

	const renderBottom = () => {
		return (
			<div className={'nav__bottom'}>
				{props.renderBottom && props.renderBottom({collapsed: props.collapsed})}
				{!props.collapsed && (
					<>
						{renderContact()}
						{renderTerms()}
					</>
				)}
			</div>
		);
	};

	return (
		<div className={`Navigation ${props.collapsed ? 'Navigation--collapsed' : ''}`}>
			<div className={'nav__top'}>
				{renderHeader()}

				<div className={'nav__breaker'}></div>

				{renderUser()}

				{renderNavItems(props.navItems)}
			</div>

			{renderBottom()}
		</div>
	);
}

export default Navigation;
