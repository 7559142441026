import React, { useEffect, useState } from 'react';
import './DateTimeRangeInput.scss';
import DatePicker from './../DatePicker';
import { Popover } from 'react-tiny-popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

const isDate = d => typeof d?.getTime === 'function';

export const getActualDayOfWeek = date => {
	const americanWeekday = date.getDay();
	const dow = americanWeekday === 0 ? 7 : americanWeekday;
	return dow;
};

export default function DateTimeRangeInput(props) {
	const {
		value,
		onChange,
		disabled,
		display,
		noTime
	} = props;

	const [currentMonth, setCurrentMonth] = useState();
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	// DatePicker needs a currentMonth
	useEffect(() => {
		if (!value || (!value[0] && !value[1])) setCurrentMonth(new Date());
	}, [value]);

	useEffect(() => {
		if(disabled || display){
			setIsPopoverOpen(false);
		}
	}, [disabled, display])

	const getPrettyDate = date => {
		if (!date) return null;
		const dayOfWeek = props.translations.days[getActualDayOfWeek(date) - 1];
		const day = date.getDate();
		const month = props.translations.months[date.getMonth()];
		const year = date.getFullYear();

		return `${dayOfWeek} ${day} ${month} ${year}`;
	};

	const formatDate = d => {
		if (!isDate(d)) return null;
		const pretty = getPrettyDate(d);

		if (noTime) return pretty;
		const hours = `0${d.getHours()}`.slice(-2);
		const minutes = `0${d.getMinutes()}`.slice(-2);

		return `${pretty} ${hours}:${minutes}`;
	};

	return (
		<div
			className={`DateTimeRangeInput ${disabled ? 'disabled' : ''} ${display ? 'display' : ''}`}
		>
			<Popover
				isOpen={isPopoverOpen}
				positions={['bottom', 'top']}
				align={'start'}
				onClickOutside={() => setIsPopoverOpen(false)}
				padding={3}
				content={
					<div className='PopoverContent'>
						<DatePicker
							translations={props.translations}
							options={{
								//hideWeekNumbers: true,
								//hideDaysOfPrevAndNextMonth: true,
								//hideSelectedDates: true,
								//showSuggestions: true,
								highlightedDates: props.highlightedDates ?? false
							}}
							value={value}
							onChange={onChange}
							currentMonth={currentMonth}
						/>
					</div>
				}
			>
				<div className='date-range' onClick={() => (disabled || display) ? null : setIsPopoverOpen(true)}>
					<span className={`Input ${isPopoverOpen ? 'focus' : ''} start`}>{formatDate(value?.[0])}</span>
					<FontAwesomeIcon icon={faLongArrowAltRight} />
					<span className={`Input ${isPopoverOpen ? 'focus' : ''} end`}>{formatDate(value?.[1])}</span>
				</div>
			</Popover>
		</div>
	);
}
