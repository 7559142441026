import React from 'react';
import './SupplierBadge.scss';

export default function SupplierBadge(props) {
	return (
		<div className='SupplierBadge'>
			<span className='supplier__code'>{props.supplier?.code}</span>
			<span className='supplier__name'>{props.supplier?.name}</span>
		</div>
	);
}
