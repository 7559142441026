import React from 'react';
import './ListFilters.scss';

export default function NumberFilter({ value, setValue }) {
	return (
		<div className='ListFilter NumberFilter'>
			<input type='number' value={value} onChange={e => setValue(e.target.value)} />
		</div>
	);
}
