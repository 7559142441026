// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2Pn-KTNIjyArTEFTupE5sM{width:100%;max-width:130rem;display:flex;flex-direction:column;justify-content:center}._2Pn-KTNIjyArTEFTupE5sM img{max-height:100%;width:100%}._1C_0f-NBFCo-RUp-2FQFe9{font-size:inherit}.vP8q75YWVhuIeBcbGF39T{cursor:pointer}._3tdzdihJJZCO39rTM-HoQX{margin-top:1rem;display:flex}._3tdzdihJJZCO39rTM-HoQX ._1C_0f-NBFCo-RUp-2FQFe9{flex:1;max-height:17.6rem}._3tdzdihJJZCO39rTM-HoQX ._1C_0f-NBFCo-RUp-2FQFe9+._1C_0f-NBFCo-RUp-2FQFe9{margin-left:1.3rem}@media(max-width: 650px){._3tdzdihJJZCO39rTM-HoQX{flex-direction:column}._3tdzdihJJZCO39rTM-HoQX ._1C_0f-NBFCo-RUp-2FQFe9+._1C_0f-NBFCo-RUp-2FQFe9{margin-left:0;margin-top:1rem}}", "",{"version":3,"sources":["webpack://./src/components/HeroBanners/HeroBanners.module.scss"],"names":[],"mappings":"AAAA,yBACC,UAAA,CACA,gBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,6BACC,eAAA,CACA,UAAA,CAIF,yBACC,iBAAA,CAGD,uBAEC,cAAA,CAGD,yBACC,eAAA,CACA,YAAA,CACA,kDACC,MAAA,CACA,kBAAA,CACA,2EACC,kBAAA,CAMH,yBACC,yBACC,qBAAA,CACA,2EACC,aAAA,CACA,eAAA,CAAA","sourcesContent":[".HeroBanners {\n\twidth: 100%;\n\tmax-width: 130rem;\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\timg {\n\t\tmax-height: 100%;\n\t\twidth: 100%;\n\t}\n}\n\n.banner{\n\tfont-size: inherit; // .banner needs to exist to be composed so we set some property\n}\n\n.pointerBanner{\n\tcomposes: banner;\n\tcursor: pointer;\n}\n\n.secondary {\n\tmargin-top: 1rem;\n\tdisplay: flex;\n\t.banner {\n\t\tflex: 1;\n\t\tmax-height: 17.6rem;\n\t\t+ .banner {\n\t\t\tmargin-left: 1.3rem;\n\t\t}\n\t}\n}\n\n\n@media (max-width: 650px) {\n\t.secondary {\n\t\tflex-direction: column;\n\t\t.banner + .banner{\n\t\t\tmargin-left: 0;\n\t\t\tmargin-top: 1rem;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeroBanners": "_2Pn-KTNIjyArTEFTupE5sM",
	"banner": "_1C_0f-NBFCo-RUp-2FQFe9",
	"pointerBanner": "vP8q75YWVhuIeBcbGF39T _1C_0f-NBFCo-RUp-2FQFe9",
	"secondary": "_3tdzdihJJZCO39rTM-HoQX"
};
export default ___CSS_LOADER_EXPORT___;
