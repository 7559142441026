import './PaginationController.scss';
import React from 'react';
import Button from "../Button";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faAngleLeft,
	faAngleRight,
} from "@fortawesome/free-solid-svg-icons";


export default function PaginationController(props) {
	const {
		page,
		pages,
		setPage,
		leading,
		trailing,
		around
	} = props;
	if(page >= pages) setPage(pages - 1);
	const totalButtons = leading + 1 + around + 1 + around + 1 + trailing;

	const renderButton = (pageIndex) => (
		<Button
			key={pageIndex}
			onClick={() => setPage(pageIndex)}
			className={page === pageIndex ? 'page selected' : 'page'}
			borderOnly
		>
			{pageIndex + 1}
		</Button>
	);
	const renderEllipsis = (type) => (<span key={`ellipsis-${type}`} className={'ellipsis'}>· · ·</span>);

	return (
		<div className={'PaginationController'} data-selected={page}>
			<Button
				className={'back'}
				onClick={() => setPage(page - 1)} disabled={page === 0}
				key={'back'}
				icon={<FontAwesomeIcon icon={faAngleLeft} />}
			/>
			{(pages <= totalButtons) && ([...Array(pages)].map((_, i) => renderButton(i)))}
			{(pages > totalButtons) && ([...Array(totalButtons)].map((_, i) => {
				// TODO use declarative variables instead of magic checks.

				if (i < leading) return renderButton(i);
				if (i === leading){
					if (page <= leading + around + 1) return renderButton(i);
					return renderEllipsis('begin');
				}
				if (i >= totalButtons - trailing){
					return renderButton((pages - trailing) + i - (totalButtons - trailing));
				}
				if (i === totalButtons - trailing - 1){
					if (page >= pages - trailing - around - 2) return renderButton((pages - trailing) + i - (totalButtons - trailing));
					return renderEllipsis('end');
				}
				if (page <= leading + 1 + around){
					return renderButton(i);
				}
				if (page >= pages - (trailing + 1 + around)){
					return renderButton(pages - (trailing + 1 + around + 1 + around) + (i - leading - 1));
				}
				return renderButton((i  - leading - 1) + page - around);
			}))}

			<Button
				className={'next'}
				onClick={() => setPage(page + 1)}
				disabled={page === pages - 1}
				key={'forward'}
				icon={<FontAwesomeIcon icon={faAngleRight} />}
			/>
		</div>
	);
};
