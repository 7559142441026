import React from 'react';

export default function CharlesLogo() {
	return (
		<svg version="1.1" id="CharlesLogoSvg" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			viewBox="0 0 566.9 212.6">
			<path d="M539.4,91.1c-16.4-9.3,4.4-10.7,12.3-11.4c7.4-0.7,6-9.8,3.4-13c-5.4-6.9-10.9-4-25.3,3.6c-12.3,6.5-17.2,21.4-21.6,27.7
	c-5.7,8-9.5,16.3-13.8,22.4l-0.1,0.2c-7.4,10.3-16.7,19.1-27.3,19.1c-9.8,0-13.1-12.8-11.2-30.9c25.3,3.6,32.9-7.7,35.9-21.2
	c3-13.8-4.6-30.9-19.1-31.2c-21.4-0.3-32.9,32.2-34.6,43.6c-0.5,3.1-2.5,9.7-0.7,22.2c-3.9,7.9-11.6,20.8-17.6,19.1
	c-6.9-2-5.2-23.7-4.7-36.6c0,0,2.7-59.1,4.3-80.6c0.6-7.9,2.4-24.1-8.5-24.1c-8.4,0-10.1,12.8-10.9,20.7
	c-3.6,34.1-5.3,61.3-5.2,82.4l0.1,6.5c-6.9,11.4-16.7,31.8-24.8,29.4c-8.9-2.6-0.4-29,1.3-35.8c2-7.5-0.6-21.9-18-13.4
	c2.3-9.5,3.2-15.4-6.2-15.6c-5.9-0.1-7.9,2-8.9,7.4c-1,5.4-3.1,27.1-6.4,38c-3,9.8-14.2,22.2-15.6,9.4c-0.3-9.4-0.5-20,0.2-28.4
	c1.4-18.9,1.2-23.5-5.4-24c-4-0.3-7,1.5-9.1,3.5c-4.5-4.2-10.2-7.7-19-7.7c-9.7,0-20.6,9.8-25.5,29.8c-2.5,5.2-10.5,22-15.1,29
	c-5.5,8.4-9.9,0-8.9-6.9c1-6.9,8.9-44.9-2-51.3c-10.9-6.4-28.3,26.1-33.7,35.5c0,0,4.5-69.5,5.5-77.4c1-7.9,0-23.7-10.9-23.7
	c-8.4,0-10.4,12.8-10.9,20.7c-0.3,5.3-2.7,53.7-4.4,88.4l-0.3,7.1c-9.6,5.4-21.1,11.5-29.6,14.6c-14.2,5.3-30.9,9-48.9,7.7
	c-39.3-2.8-65.6-16-74.5-48.9c-9.8-36.5,30.1-65.8,59.3-74.1c27.2-7.8,53.7-7.6,57.7,14.1c4,21.7-12.1,27.8-28.8,32.1
	c-5.6,1.5-11.9,2.2-17.7,2.2c-11.4-0.1-19.4,15.4-2,17.5c6.6,0.8,13.4,0.7,20-0.1c23.8-3.2,53.9-21.7,50-52.1
	C157.3,2.4,121.5-5.4,80.2,4.9C38.9,15.2-12.4,55.3,2.7,104.6c11.4,37.2,48,63.6,97.2,59.5c14.8-1.2,30.7-5.2,47.4-12.4
	c6.7-2.9,18.1-9,28.3-14.9c-0.3,6.7-0.5,11.8-0.6,14.1c-0.5,13.3,12.5,14.1,17.8,1c6.4-15.8,21.3-48.3,21.3-48.3
	c-1,6.4-8.9,43.9,8.9,45.4c15.6,1.3,27.5-18.6,31.5-29.2c-0.1,1.5-0.1,3.1-0.2,4.7c-0.5,35,15.7,39.3,23.8,39.8
	c7,0.4,15.5-8.8,21-22.4c0.6,1.1,1.4,2.1,2.2,3c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.4,0.8,0.8,1.3,1.2c3,2.6,6.7,4.2,10.4,4.5
	c5.9,1.3,12.8,0.9,17.4-4.8c8.9-10.8,11.9-23.6,14-36.4c1.8-11.1,11.5-13.4,10-4c-1.5,9.4-2.8,25.5-3.7,32.5
	c-1.6,12.5,16.9,19.6,26.3,8.8c5.7-6.6,13.7-20.1,17.5-29.2c1.6,28.7,7.6,42,17.4,42.4c11.4,0.4,23.2-15.5,27.4-27.8
	c3,8,8.4,15.1,18.1,18.6c25.1,9.1,41-15.9,47.5-27.4c0.2-0.2,0.3-0.5,0.5-0.7c11.4-17.5,13-28.3,20.6-23.4
	c5.7,3.7,23.8,17,23.8,26.4s-6.5,15.9-11.6,15.2c-5.1-0.7-7.8-5.2-8-13.9c-0.2-7.8-2.8-13.5-10.1-12.8c-7.3,0.7-6.4,12.6-5.7,17.7
	c0.7,5.1,4.2,20.6,23.8,19.8c19.6-0.7,28.4-14.7,28.4-27.7S556.1,100.6,539.4,91.1z M471.5,69.2c3.2-1.6,9.6,0.3,6.6,16.4
	c-2.7,14.7-14.6,11.8-20.6,11C460.8,80.7,468,70.9,471.5,69.2z M299.2,111.8c-1.9,35.5-15.2,41.7-20,41.2
	c-4.8-0.5-8.6-18.9-8.1-34.1c0.5-15.2,7.6-33.1,16.2-33.1c5.6,0,9.2,2.9,11.7,6.5l0.8,9.4C299.6,104.3,299.4,107.7,299.2,111.8z"/>
			<g>
				<path d="M200.5,206.6c0,1.9-0.7,3.3-2,4.4c-1.3,1.1-3.2,1.6-5.6,1.6c-2.2,0-4.1-0.4-5.8-1.2v-4c1.4,0.6,2.6,1.1,3.5,1.3
		c1,0.3,1.8,0.4,2.6,0.4c1,0,1.7-0.2,2.2-0.5c0.5-0.4,0.8-0.9,0.8-1.6c0-0.4-0.1-0.8-0.3-1.1c-0.2-0.3-0.6-0.6-1-0.9
		c-0.4-0.3-1.3-0.8-2.7-1.4c-1.3-0.6-2.2-1.2-2.8-1.7c-0.6-0.5-1.1-1.2-1.5-1.9c-0.4-0.7-0.6-1.6-0.6-2.5c0-1.8,0.6-3.2,1.8-4.3
		c1.2-1,2.9-1.6,5.1-1.6c1.1,0,2.1,0.1,3.1,0.4c1,0.3,2,0.6,3,1.1l-1.4,3.4c-1.1-0.5-2-0.8-2.7-0.9c-0.7-0.2-1.4-0.3-2.1-0.3
		c-0.8,0-1.5,0.2-1.9,0.6c-0.4,0.4-0.7,0.9-0.7,1.5c0,0.4,0.1,0.7,0.3,1c0.2,0.3,0.5,0.6,0.9,0.8c0.4,0.3,1.3,0.7,2.8,1.4
		c1.9,0.9,3.2,1.8,4,2.8S200.5,205.3,200.5,206.6z"/>
				<path d="M208.5,212.3v-20.6h4.4v20.6H208.5z" />
				<path d="M240.6,212.3h-5.5l-8.9-15.5H226c0.2,2.7,0.3,4.7,0.3,5.9v9.7h-3.9v-20.6h5.5l8.9,15.4h0.1c-0.1-2.7-0.2-4.6-0.2-5.7v-9.7
		h3.9V212.3z"/>
				<path d="M258.8,195.1c-1.6,0-2.9,0.6-3.8,1.8c-0.9,1.2-1.4,2.9-1.4,5.2c0,4.6,1.7,6.9,5.2,6.9c1.4,0,3.2-0.4,5.2-1.1v3.7
		c-1.7,0.7-3.6,1.1-5.7,1.1c-3,0-5.3-0.9-6.9-2.7c-1.6-1.8-2.4-4.4-2.4-7.8c0-2.1,0.4-4,1.2-5.6c0.8-1.6,1.9-2.8,3.4-3.7
		c1.5-0.9,3.2-1.3,5.1-1.3c2,0,4,0.5,6,1.4l-1.4,3.5c-0.8-0.4-1.5-0.7-2.3-1C260.4,195.2,259.6,195.1,258.8,195.1z"/>
				<path d="M284.6,212.3h-11.8v-20.6h11.8v3.6h-7.5v4.5h7v3.6h-7v5.3h7.5V212.3z" />
				<path d="M314.3,212.3H310v-11.9l0-2l0.1-2.1c-0.7,0.7-1.2,1.2-1.5,1.4l-2.4,1.9l-2.1-2.6l6.6-5.3h3.6V212.3z" />
				<path d="M338.6,200.5c0,4-0.9,7.1-2.6,9.1c-1.7,2-4.3,3-7.7,3c-1.2,0-2.1-0.1-2.8-0.2v-3.5c0.8,0.2,1.6,0.3,2.5,0.3
		c1.5,0,2.6-0.2,3.6-0.6c0.9-0.4,1.7-1.1,2.2-2c0.5-0.9,0.8-2.2,0.9-3.8h-0.2c-0.5,0.9-1.2,1.5-1.9,1.9c-0.7,0.4-1.6,0.5-2.7,0.5
		c-1.8,0-3.2-0.6-4.2-1.7c-1-1.1-1.5-2.7-1.5-4.8c0-2.2,0.6-3.9,1.9-5.2c1.3-1.3,3-1.9,5.1-1.9c1.5,0,2.8,0.4,4,1.1
		c1.1,0.7,2,1.7,2.6,3.1C338.3,197,338.6,198.7,338.6,200.5z M331.2,195c-0.9,0-1.6,0.3-2.1,0.9c-0.5,0.6-0.8,1.5-0.8,2.7
		c0,1,0.2,1.8,0.7,2.4c0.5,0.6,1.2,0.9,2.1,0.9c0.9,0,1.6-0.3,2.3-0.9c0.6-0.6,0.9-1.2,0.9-2c0-1.1-0.3-2.1-0.9-2.8
		C332.8,195.4,332.1,195,331.2,195z"/>
				<path d="M358.7,196.4c0,1.3-0.4,2.4-1.2,3.3c-0.8,0.9-1.9,1.5-3.3,1.9v0.1c1.7,0.2,2.9,0.7,3.8,1.5c0.9,0.8,1.3,1.9,1.3,3.2
		c0,2-0.7,3.5-2.2,4.6c-1.4,1.1-3.5,1.7-6.1,1.7c-2.2,0-4.2-0.4-5.9-1.1v-3.7c0.8,0.4,1.7,0.7,2.6,1s1.9,0.4,2.8,0.4
		c1.4,0,2.5-0.2,3.2-0.7c0.7-0.5,1-1.3,1-2.3c0-1-0.4-1.7-1.2-2.1c-0.8-0.4-2-0.6-3.8-0.6h-1.6v-3.3h1.6c1.6,0,2.8-0.2,3.5-0.6
		c0.7-0.4,1.1-1.1,1.1-2.1c0-1.6-1-2.3-2.9-2.3c-0.7,0-1.4,0.1-2.1,0.3c-0.7,0.2-1.5,0.6-2.3,1.2l-2-3c1.9-1.4,4.1-2,6.7-2
		c2.1,0,3.8,0.4,5,1.3C358.1,193.6,358.7,194.8,358.7,196.4z"/>
				<path d="M373.3,199.2c2,0,3.6,0.6,4.7,1.7c1.2,1.1,1.8,2.6,1.8,4.6c0,2.3-0.7,4.1-2.1,5.3c-1.4,1.2-3.4,1.9-6.1,1.9
		c-2.3,0-4.1-0.4-5.5-1.1v-3.8c0.7,0.4,1.6,0.7,2.6,1c1,0.2,1.9,0.4,2.8,0.4c2.7,0,4-1.1,4-3.3c0-2.1-1.4-3.1-4.1-3.1
		c-0.5,0-1,0-1.6,0.1c-0.6,0.1-1.1,0.2-1.5,0.3l-1.7-0.9l0.8-10.5h11.1v3.7h-7.3l-0.4,4l0.5-0.1
		C371.8,199.3,372.5,199.2,373.3,199.2z"/>
			</g>
		</svg>
	);
}
