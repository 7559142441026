import React from 'react';
import './Highlight.scss';

export default function Highlight(props) {

	const replaceIgnoredCharacters = (string) => {
		const ignoredChars = ['\'', '-'];

		ignoredChars.forEach(char => {
			string = string.replace(char, `${char}?`);
		});

		return string;
	};

	const makeRegExp = (strings) => {
		let words = strings.map(w => {
			w = w.replace(/[\\.+*?^$[\](){}/#:!=|]/gi, '\\$&');
			w = replaceIgnoredCharacters(w);
			return w;
		});
		words = words.join('|');

		const regexp = new RegExp(`(${words})`, 'gi');
		return regexp;
	};

	const partMatches = (part, string) => {
		const regexp = makeRegExp(string.split(' '));
		const contains = !!part.match(regexp);
		return contains;
	};

	const highlight = () => {
		if (props.string === '' || props.text === '' || !props.text) return props.text;

		const regexp = makeRegExp(props.string.trim().replace(/ +/g, ' ').split(' '));

		const parts = props.text.toString().split(regexp);

		return (
			<span>
				{parts.map((part, key) => (
					<span
						key={key}
						className={partMatches(part.trim(), props.string.replace(/ +/g, ' ').trim()) ? 'highlight' : ''}
					>
						{part}
					</span>
				))}
			</span>
		);
	};

	const highlighted = highlight();

	return (
		<div className={`Highlight ${highlighted === props.text ? 'not-highlighted' : 'highlighted'}`}>
			{highlighted}
		</div>
	);
}
