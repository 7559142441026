import React, {useState} from 'react';
import './Checkbox.scss';
import CheckboxIcon from "./CheckboxIcon";

function Checkbox(props) {
	const {
		id,
		onToggle,
		inverted,
		radio,
		disabled,
		display,
		checked,
		children
	} = props;
	const [hover, setHover] = useState(false);

	if (id === undefined) throw new Error('checkbox needs an id to function');

	const getClassesString = () => {
		const classes = ['Checkbox'];
		if (props.className) classes.push(props.className);
		if (props.radio) classes.push('radio');
		if (props.inverted) classes.push('inverted');
		if (props.display) classes.push('display');
		if (typeof props.checked === 'string') classes.push(props.checked);
		return classes.join(' ');
	};

	const checkedState = typeof props.checked !== 'string' ? props.checked : false;

	return (
		<div className={getClassesString()} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
			<input
				type={ !radio ? 'checkbox' : 'radio' }
				id={id}
				checked={checkedState}
				onChange={onToggle ?? (() => {})}
				disabled={disabled || display}
			/>
	        <label htmlFor={id}>
		        <CheckboxIcon inverted={inverted ?? checked === 'exclude'} radio={radio} disabled={disabled} display={display} checked={!!checked === true} hoverOver={hover}/>
		        {children}
	        </label>
		</div>
	);
}

export default Checkbox;
