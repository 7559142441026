import React, { useEffect, useState } from 'react';
import './DateTimeInput.scss';
import Input from './../Input';
import DatePicker from './../DatePicker';
import { Popover } from 'react-tiny-popover';

const isDate = d => typeof d.getTime === 'function';

export default function DateTimeInput(props) {
	const {
		value,
		onChange
	} = props;

	const [dateString, setDateString] = useState();
	const [currentMonth, setCurrentMonth] = useState();
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	useEffect(() => {
		if (value) setDateString(formatDate(value));
	}, [value]);

	// DatePicker needs a currentMonth
	useEffect(() => {
		if (!value) setCurrentMonth(new Date());
	}, [value]);

	const formatDate = d => {
		if (!isDate(d)) return d;
		const date = new Date(d.getTime());
		const year = date.getFullYear();
		const month = `0${date.getMonth() + 1}`.slice(-2);
		const day = `0${date.getDate()}`.slice(-2);

		const hours = `0${date.getHours()}`.slice(-2);
		const minutes = `0${date.getMinutes()}`.slice(-2);

		return `${day}-${month}-${year} ${hours}:${minutes}`;
	};

	const parseDate = string => {
		const regex = /([0-9]{2})-([0-9]{2})-([0-9]{4}) ([0-9]{2}):([0-9]{2})/;
		const result = string.match(regex);
		if (result) {
			const day = parseInt(result[1]);
			const month = parseInt(result[2]);
			const year = parseInt(result[3]);
			const hours = parseInt(result[4] ?? 0);
			const minutes = parseInt(result[5] ?? 0);

			const date = new Date(year, month - 1, day, hours, minutes);
			return date;
		}

		return undefined;
	};

	const handleTextChange = e => {
		const string = e.target.value;
		setDateString(string);

		const date = parseDate(string);
		if (date) setCurrentMonth(date);
		onChange(date);
	};

	return (
		<div
			className={`DateTimeInput`}
		>
			<Popover
				isOpen={isPopoverOpen}
				positions={['bottom', 'top']}
				align={'start'}
				onClickOutside={() => setIsPopoverOpen(false)}
				padding={3}
				content={
					<div className='PopoverContent'>
						<DatePicker
							translations={props.translations}
							options={props?.options}
							value={value}
							onChange={onChange}
							currentMonth={currentMonth}
							suggestions={props?.suggestions}
						/>
					</div>
				}
			>
				<Input
					type='text'
					value={dateString}
					onChange={handleTextChange}
					onClick={() => setIsPopoverOpen(!props.disabled && !isPopoverOpen)}
					disabled={props.disabled}
				/>
			</Popover>
		</div>
	);
}
