import React from 'react';
import './Input.scss';

const Input = React.forwardRef((props, ref) => (
	<input
		{...props}
		className={`Input ${props?.className ?? ''}`}
		ref={ref}
		disabled={props.disabled}
	/>
));

// export default function Input(props) {
// 	return <input
// 		{...props}
// 		className={`Input ${props?.className ?? ''}`}
// 		// ref={props.elementRef}
// 	/>;
// }

export default Input;