import React from 'react';
import './CharlesTitle.scss';

function CharlesTitle(props) {
	return (
		<h1 className={`CharlesTitle ${props.alt ? 'alt' : ''}`}>
			<span>
				{props.children}
			</span>
		</h1>
	);
}

export default CharlesTitle;
