import React from 'react';
import { Link } from 'react-router-dom';
import Button from './../Button';

export default function ButtonLink(props) {
	const { children, to, ...restProps } = props;

	return (
		<div className='ButtonLink'>
			<Link to={to}>
				<Button
					{...restProps}
				>
					{children ?? null}
				</Button>
			</Link>
		</div>
	);
}