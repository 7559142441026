import React from 'react';
import ArrowLeft from './../ArrowLeft';
import ButtonLink from './../ButtonLink';
import './BackButton.scss';

export default function BackButton(props) {
	const { to, children, ...restProps } = props;

	return (
		<ButtonLink
			to={to}
			textOnly
			icon={<ArrowLeft />}
			className='BackButton'
			{...restProps}
		>
			{children ?? null}
		</ButtonLink>
	);
}
