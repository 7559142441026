import React, { useEffect, useState } from 'react';
import './ColorInput.scss';
import { Popover } from 'react-tiny-popover';
import { useColor, ColorPicker } from 'react-color-palette';

const SUGGESTIONS = [
	'#C1AD76',
	'#C1222F'
];

export default function ColorInput(props) {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [color, setColor] = useColor('hex', props.value);
	const [colorText, setColorText] = useState(props.value);
	const suggestions = props.suggestions ?? SUGGESTIONS;

	useEffect(() => {
		const isColor = /#([0-9a-fA-F]{6})/.test(color.hex);
		if (isColor) props.onChange(color.hex);
	}, [color]);

	useEffect(() => {
		const isColor = /#([0-9a-fA-F]{6})/.test(colorText);
		if (isColor) props.onChange(colorText);
	}, [colorText]);

	useEffect(() => {
		if (colorText !== props.value) setColorText(props.value);
	}, [props.value]);

	const renderSuggestions = () => (
		<div className='suggestions'>
			{suggestions.map(color => (
				<div
					className='suggestion'
					style={{ '--color': color }}
					onClick={() => props.onChange(color)}
				/>
			))}
		</div>
	);

	return (
		<div className='ColorInput'>
			<Popover
				isOpen={isPopoverOpen}
				positions={['bottom', 'top']}
				align={'start'}
				onClickOutside={() => setIsPopoverOpen(false)}
				padding={3}
				content={
					<div className='PopoverContent ColorInput'>
						<ColorPicker
							width={300}
							height={228}
							color={color}
							onChange={setColor}
							format={'hex'}
							hideHSB
							hideHEX
							hideRGB
						/>
						{renderSuggestions()}
					</div>
				}
			>
				<div>
					<span style={{ '--color': props.value }} className='color-example' />
					<input
						className='Input color-input'
						type={'text'}
						value={colorText}
						onChange={e => setColorText(e.target.value)}
						onClick={() => setIsPopoverOpen(!props.disabled)}
						disabled={props.disabled}
					/>
				</div>
			</Popover>

		</div>
	);
}
