import React, { useState } from 'react';
import './SuppliersInput.scss';
import SupplierBadge from '../SupplierBadge/index.js';
import Autosuggest from 'react-autosuggest';
import Highlight from '../Highlight/index.js';
import Input from '../Input';

export default function SuppliersInput(props) {
	const {
		suppliersById,
		suppliers,
	} = props;
	const minSuppliers = props.minSuppliers ?? 1;
	const [supplierInput, setSupplierInput] = useState('');
	const [suggestions, setSuggestions] = useState([]);

	const getSuggestions = (input) => {
		let suggestions = suppliers.filter((supplier) => {
			// Not the ones that are already selected
			if (props.value?.includes(supplier.id.toString())) return false;

			const matchCode = supplier.code.startsWith(input);
			const matchName = supplier.name.toLowerCase().includes(input.toLowerCase());
			return matchCode || matchName;
		});

		setSuggestions(suggestions);
	};

	const clearSuggestions = () => {
		setSuggestions([]);
	};

	const getSuggestionValue = (supplier) => {
		return `${supplier.code} ${supplier.name}`;
	};

	const renderSuggestion = (suggestion) => {
		const text = `${suggestion.code} ${suggestion.name}`;
		return <Highlight text={text} string={supplierInput} />;
	};

	const onSuggestionSelected = (e, { suggestion }) => {
		e.preventDefault();
		props.onChange([
			suggestion.id,
			...props.value ?? []
		]);
		setSupplierInput('');
	};

	const handleSupplierClick = (id) => {
		if (props.value?.length <= minSuppliers) return;
		props.onChange(props.value?.filter((supplierId) => supplierId !== id));
	};

	const renderRemoveIcon = (id) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="icon icon-tabler icon-tabler-x"
			viewBox="0 0 24 24"
			//stroke-linejoin="round"
			onClick={(e) => handleSupplierClick(id)}
		>
			{/* <path stroke="none" d="M0 0h24v24H0z" /> */}
			<line x1="18" y1="6" x2="6" y2="18" />
			<line x1="6" y1="6" x2="18" y2="18" />
		</svg>
	);

	return (
		<div className='SuppliersInput'>
			<Autosuggest
				suggestions={suggestions}
				onSuggestionsFetchRequested={({ value }) => getSuggestions(value)}
				onSuggestionsClearRequested={clearSuggestions}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				inputProps={{
					value: supplierInput,
					onChange: (e) => setSupplierInput(e.target.value),
					placeholder: props.placeholder,
					disabled: props.disabled
				}}
				onSuggestionSelected={onSuggestionSelected}
				highlightFirstSuggestion
				renderInputComponent={inputProps => <Input {...inputProps} />}
			/>

			<div className={'suppliers'}>
				{props.value?.map((id) => (
					<div key={id} className={'supplier__item'}>
						<SupplierBadge supplier={suppliersById[id]} />
						{props.value?.length > minSuppliers && renderRemoveIcon(id)}
					</div>
				))}
			</div>
		</div>
	);
}
