import React, { useState, useEffect } from 'react';
import './List.scss';
import PaginationController from "../Pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { Popover } from 'react-tiny-popover';

function List(props) {
	const {
		header,
		action,
		pagination
	} = props;

	const {
		itemsPerPage,
		page,
		setPage,
		leading,
		trailing,
		around
	} = pagination ?? {};

	const [sortKey, setSortKey] = useState(null);
	const [sortDirection, setSortDirection] = useState(null);
	const [filters, setFilters] = useState({});
	const [openFilter, setOpenFilter] = useState(null);

	const paginated = !!itemsPerPage;

	const gridTemplateColumns = props.gridTemplateColumns ?? `repeat(${header.length}, 1fr)`;

	const isFilterActive = (filter, key) => {
		if (filter?.isFilterActive) return filter.isFilterActive(filters?.[key]);
		return filters?.[key];
	};

	const getContent = () => {
		let content = [ ...props.content ?? []];

		// Filtering
		header.forEach(column => {
			const filterValue = filters?.[column.key]
			if (isFilterActive(column.filter, column.key)) content = content.filter(item => column.filter?.test(item, filterValue));
		});

		// Sorting
		if (sortKey && sortDirection) content = content.sort(header.find(h => h.key === sortKey)?.sort);
		if (sortDirection === 'descending') content = content.reverse();

		return content;
	};

	const handleSortClick = (key) => {
		if (sortKey !== key) {
			setSortKey(key);
			setSortDirection('ascending');
			return;
		}

		if (sortDirection === 'ascending') return setSortDirection('descending');
		if (sortDirection === 'descending') {
			setSortKey(null);
			setSortDirection(null);
			return;
		}
		setSortDirection('ascending');
	};

	const renderHeaderSort = (key) => {
		const getSortIcon = () => {
			if (sortKey !== key) return faSort;
			if (sortDirection === 'ascending') return faSortUp;
			if (sortDirection === 'descending') return faSortDown;
			return faSort;
		};

		return (
			<div className={`header__sort ${key === sortKey ? 'active' : ''}`} onClick={() => handleSortClick(key)}>
				<FontAwesomeIcon icon={getSortIcon()} />
			</div>
		);
	};

	const renderOpenFilter = (key, filter) => {
		const value = filters?.[key];
		const setValue = value => setFilters({
			...filters,
			[key]: value
		});

		let render = null;
		if (filter.render) render = filter.render;
		if (filter.component) {
			const Component = filter.component;
			render = (value, setValue) => <Component value={value} setValue={setValue} />;
		}

		return (
			<div className='header__filter-content'>
				{render(value, setValue)}
			</div>
		);
	};

	const renderHeaderFilter = (key, filter) => {
		const handleFilterClick = (e) => {
			e.stopPropagation();
			setOpenFilter(openFilter !== key ? key : null);
		};

		return (
			<Popover
				isOpen={openFilter === key}
				positions={['bottom', 'top']}
				align={'middle'}
				onClickOutside={() => setOpenFilter(null)}
				padding={0}
				content={renderOpenFilter(key, filter)}
			>
				<div className={`header__filter ${isFilterActive(filter, key) ? 'active' : ''}`} onClick={handleFilterClick}>
					<FontAwesomeIcon icon={faFilter} />
				</div>
			</Popover>
		);
	};

	const renderHeaderColumn = ({ renderHeader, key, title, sort, filter }) => (
		<div key={key} className={`header__column ${key} ${sort ? 'sortable' : ''}`}>
			<div className='header__content' onClick={() => { if (sort) handleSortClick(key) }}>
				<div className='left'>
					{renderHeader ? renderHeader({ title, key }) : title}
				</div>
				<div className='right'>
					{filter && renderHeaderFilter(key, filter)}
					{sort && renderHeaderSort(key)}
				</div>
			</div>
		</div>
	);

	const renderItemCell = (column, listItem) => (
		<div key={column.key} className={`item__cell ${column.key}`}>
			{column.render(listItem)}
		</div>
	);

	const renderSuspenseItemCell = (column) => (
		<span key={column.key} className={`item__cell ${column.key} suspense`} />
	);

	const renderListItem = (listItem, index) => {
		if (
			paginated &&
			(index < (page * itemsPerPage)) ||
			(index >= ((page + 1) * itemsPerPage))
		) return null;

		return (
			<div
				key={listItem.id ?? index}
				className={`list__item ${action ? 'list__item-action' : ''}`}
				onClick={e => action ? action(e, listItem) : null}
			>
				{header.map((column) => renderItemCell(column, listItem))}
			</div>
		);
	};

	const renderSuspenseListItem = (_, index) => (
		<div
			key={index}
			className={'list__item'}
		>
			{header.map((column) => renderSuspenseItemCell(column))}
		</div>
	);

	const content = getContent();

	return (
		<div className={'List'} >
			<div className={'list__wrapper'} style={{ ['--columns']: gridTemplateColumns }}>
				<div className={'list__header'} >
					{header.map(renderHeaderColumn)}
				</div>
				<div className={'list__body'}>
					{content.map(renderListItem)}
					{!props.content && [ ...Array(itemsPerPage ?? 12)].map(renderSuspenseListItem)}
				</div>
			</div>

			{paginated && content?.length > itemsPerPage && (
				<PaginationController
					page={page}
					setPage={setPage}
					pages={Math.ceil(content.length / itemsPerPage)}
					leading={leading ?? 1}
					trailing={trailing ?? 1}
					around={around ?? 2}
				/>
			)}
		</div>
	);
}

export default List;
