import React from 'react';
import FlextArea from '../FlextArea';
import Checkbox from './../Checkbox';
import DateTimeInput from './../DateTimeInput';
import ColorInput from './../ColorInput';

import './DynamicForm.scss';
import DateTimeRangeInput from './../DateTimeRangeInput';
import FileInput from './../FileInput';
import PermissionsInput from './../PermissionsInput';

export const FieldType = {
	Text: 'text',
	Email: 'email',
	Phone: 'tel',
	Url: 'url',
	Number: 'number',
	Range: 'range',
	Date: 'date',
	Time: 'time',
	DateTime: 'datetime',
	DateTimeRange: 'datetimerange',
	Dropdown: 'select',
	Radio: 'radio',
	Checkbox: 'checkbox',
	File: 'file',
	Password: 'password',
	Search: 'search',
	Submit: 'submit',
	Custom: 'custom',
	TextArea: 'textarea',
	Color: 'color',
	Permissions: 'permissions'
};

function DynamicForm(props) {
	const {
		values,
		sections,
		onChange,
		nested,
		formId
	} = props;

	const handleFieldChange = (action) => {
		if (action.field.setValue) return onChange({ ...action.field.setValue(values, action.value) });

		onChange({
			...values,
			[action.field.name]: action.value
		});
	};

	const handleFormEvent = (field, e) => handleFieldChange({ field, value: e.target.value });

	const arrayToHTMLSafeString = (array) => `${formId ?? ''}${array.toString().replaceAll(',', '_')}`;
	const getFieldName = ({ name }) => arrayToHTMLSafeString(name);

	const renderBasicField = (field) =>  (
		<div key={getFieldName(field)} className={'form__field'}>
			<label htmlFor={getFieldName(field)}>{field.title}</label>
			<input
				type={field.type}
				name={getFieldName(field)}
				id={getFieldName(field)}
				value={field.value ?? ''}
				onChange={e => handleFormEvent(field, e)}
				placeholder={field.placeholder}
				disabled={props?.disabled || field.disabled}
			/>
		</div>
	);

	const renderDropdown = (field) => (
		<div key={getFieldName(field)} className={'form__field'}>
			<label htmlFor={getFieldName(field)}>{field.title}</label>
			<select
				name={getFieldName(field)}
				id={getFieldName(field)}
				value={field.value ?? ''}
				onChange={e => handleFormEvent(field, e)}
				disabled={props?.disabled || field.disabled}
			>
				{field.options?.choices && field.options.choices.map(choice => (
					<option
						key={choice.value}
						value={choice.value}
					>
						{choice.title}
					</option>
				))}
			</select>
		</div>
	);

	const renderCheckBox = (field) => (
		<div key={getFieldName(field)} className={'form__field'}>
			<Checkbox
				id={getFieldName(field)}
				checked={field.value ?? false}
				onToggle={e => handleFieldChange({ field, value: e.target.checked })}
				disabled={props?.disabled || field.disabled}
			>
				{field.title}
			</Checkbox>
		</div>
	);
	
	const renderCustomField = (field) => {
		const CustomComponent = field.options.component;
		return (
			<div key={getFieldName(field)} className={'form__field'}>
				{!field.options.noLabel && (
					<label htmlFor={getFieldName(field)}>
						{field.title}
					</label>
				)}
				<CustomComponent
					onChange={(value) => handleFieldChange({ field, value })}
					value={field.value}
					name={getFieldName(field)}
					id={getFieldName(field)}
					placeholder={field.placeholder}
					disabled={props?.disabled || field.disabled}
					{...field.options?.customProps}
				/>
			</div>
		);
	};

	const renderSubmit = (field) => (
		<div key={getFieldName(field)} className={'form__field submit'}>
			<input
				type={field.type}
				name={getFieldName(field)}
				id={getFieldName(field)}
				disabled={props?.disabled || field.disabled}
			/>
		</div>
	);

	const renderDateTime = (field) => (
		<div key={getFieldName(field)} className={'form__field'}>
			<label htmlFor={getFieldName(field)}>{field.title}</label>
			<DateTimeInput
				value={field.value ?? ''}
				onChange={v => handleFieldChange({ field, value: v })}
				translations={field.options.translations}
				disabled={props?.disabled || field.disabled}
				options={{
					hideSelectedDates: true,
					single: true,
				}}
			/>
		</div>
	);

	const renderDateTimeRange = (field) => (
		<div key={getFieldName(field)} className={'form__field'}>
			<label htmlFor={getFieldName(field)}>{field.title}</label>
			<DateTimeRangeInput
				value={field.value ?? ''}
				onChange={v => handleFieldChange({ field, value: v })}
				translations={field.options.translations}
				highlightedDates={field.options.highlightedDates}
				disabled={props?.disabled || field.disabled}
			/>
		</div>
	);

	const renderTextArea = (field) => (
		<div key={getFieldName(field)} className={'form__field'}>
			<label htmlFor={getFieldName(field)}>{field.title}</label>
			<FlextArea
				value={field.value ?? ''}
				onChange={v => handleFieldChange({ field, value: v })}
				disabled={props?.disabled || field.disabled}
			/>
		</div>
	);

	const renderColorInput = (field) => (
		<div key={getFieldName(field)} className={'form__field'}>
			<label htmlFor={getFieldName(field)}>{field.title}</label>
			<ColorInput
				value={field.value ?? ''}
				onChange={v => handleFieldChange({ field, value: v })}
				disabled={props?.disabled || field.disabled}
				suggestions={field?.options?.suggestions}
			/>
		</div>
	);

	const renderFileInput = (field) => (
		<div key={getFieldName(field)} className='form__field file'>
			<label htmlFor={getFieldName(field)}>{field.title}</label>
			<FileInput
				baseUrl={field.options.baseUrl}
				type={field.options.type}
				accept={field.options.accept}
				t={field.options.translate}
				prefix={field.options.prefix}
				value={field.value}
				onChange={v => handleFieldChange({ field, value: v })}
				disabled={props?.disabled || field.disabled}
				preview={field.options.preview}
			/>
		</div>
	);

	const renderPermissionsInput = (field) => (
		<div key={getFieldName(field)} className={'form__field'}>
			{!field?.options?.noLabel && (
				<label htmlFor={getFieldName(field)}>
					{field.title}
				</label>
			)}
			<PermissionsInput
				onChange={(value) => handleFieldChange({ field, value })}
				value={field.value}
				name={getFieldName(field)}
				id={getFieldName(field)}
				placeholder={field.placeholder}
				disabled={props?.disabled || field.disabled}
				usePermissions={field?.options?.usePermissions}
				t={field?.options?.t}
			/>
		</div>
	);

	const getField = (item) => {
		const getValuePathFunctions = (field) => ({
			getValue: (values, field) => field.name.reduce((values, part) => values?.[part], values),
			setValue: (values, newValue) => {
				const valuePath = field.name;
				const reversedValuePath = [...valuePath].reverse();

				const newValues = reversedValuePath.reduce((newTree, part, index) => {
					const restPath = valuePath.slice(0, valuePath.length - index - 1);

					// If part is an integer, it's an index in an array
					const isArray = Number.isInteger(part);
					if (isArray) {
						const arrayValues = restPath.reduce((values, part) => values?.[part] ?? {}, values)
						return [
							...arrayValues.slice(0, part),
							newValue,
							...arrayValues.slice(part + 1)
						];
					}

					return {
						...restPath.reduce((values, part) => values?.[part] ?? {}, values),
						[part]: newTree
					};
				}, newValue);

				return newValues;
			}
		});

		const getFieldValue = (field) => {
			if (field.getValue) return field.getValue(values, field);
			return values?.[field.name];
		};

		let field = { ...item };
		if (Array.isArray(field.name)) {
			field = {
				...field,
				...getValuePathFunctions(field)
			};
		}

		return {
			...field,
			value: getFieldValue(field)
		};
	};

	const renderField = (field) => {
		if (props.isLoading) {
			return (
				<div className='isLoading form__field'>
					<span className='label'>{field.title}</span>
					<span className='loading'></span>
				</div>
			);
		}

		switch (field.type) {
			case FieldType.Text:
			case FieldType.Email:
			case FieldType.Phone:
			case FieldType.Url:
			case FieldType.Number:
			case FieldType.Password:
			case FieldType.Search:
				return renderBasicField(field);
			case FieldType.Submit:
				return renderSubmit(field);
			case FieldType.Dropdown:
				return renderDropdown(field);
			case FieldType.Checkbox:
				return renderCheckBox(field);
			case FieldType.Custom:
				return renderCustomField(field);
			case FieldType.DateTime:
				return renderDateTime(field);
			case FieldType.DateTimeRange:
				return renderDateTimeRange(field);
			case FieldType.TextArea:
				return renderTextArea(field);
			case FieldType.Color:
				return renderColorInput(field);
			case FieldType.File:
				return renderFileInput(field);
			case FieldType.Permissions:
				return renderPermissionsInput(field);
			default:
				return `${field?.title} = ${field?.value?.toString()}`;
		}
	};

	const onSubmit = (e) => {
		e.preventDefault();
		if (props.onSubmit) props.onSubmit(values);
	};

	const renderItems = (items) => items.map(i => {
		if (Array.isArray(i)) return <div key={arrayToHTMLSafeString(i)} className='subsection'>{renderItems(i)}</div>;
		const item = props.fields.find(f => getFieldName({ name: i }) === getFieldName(f));
		return renderField(getField(item));
	});

	const renderSections = () => sections.map((section, key) => (
		<div key={key} className='form__section'>
			<h2>{section.title}</h2>
			<div className='section__fields'>
				{renderItems(section.fields)}
			</div>
		</div>
	));

	// Don't try to render if fields is null
	if (!values && !props.isLoading) return null;

	const content = (
		<>
			{sections && renderSections()}
			{!sections && props.fields.map(field => renderField(getField(field)))}
		</>
	);

	if (nested) {
		return (
			<div className={`DynamicForm ${props.className}`}>
				{content}
			</div>
		);
	}

	return (
		<form className={`DynamicForm ${props.className}`} onSubmit={onSubmit}>
			{content}
		</form>
	);
}

export default DynamicForm;
