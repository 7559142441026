import React, { useState } from 'react';
import './FileUpload.scss';
import Spinner from './../Spinner';

export default function FileUpload(props) {
	const {
		t
	} = props;

	const [dragover, setDragover] = useState(false);
	
	if (props.displayStyle === 'button') {
		return (
			<div className='FileUpload buttonStyle'>
				<input
					id='file'
					name='files'
					type='file'
					onChange={(e) => props.onDrop(e.target.files)}
					onDragOver={() => setDragover(true)}
					onDragLeave={() => setDragover(false)}
					disabled={props.uploading}
					accept={props.accept}
				/>
				<label htmlFor='file' className={`uploadButton ${dragover ? ' dragover' : ''}${props.uploading ? ' loading' : ''}`}>
					{!props.uploading && (
						<span className='Button border-only' >
							{props.type === 'file' && t('upload_file_button')}
							{props.type === 'image' && t('drag_and_drop_browse_image')}
							{props.type === 'excel' && t('excel_action_upload')}
							{
								props.type !== 'image' &&
								props.type !== 'file' &&
								props.type !== 'excel' &&
								t('drag_and_drop_browse')
							}
						</span>
					)}
					{props.uploading && <Spinner />}
				</label>
			</div>
		);
	}

	return (
		<div className='FileUpload fragAndDrop'>
			<input
				id='file'
				name='files'
				type='file'
				onChange={(e) => props.onDrop(e.target.files)}
				onDragOver={() => setDragover(true)}
				onDragLeave={() => setDragover(false)}
				disabled={props.uploading}
				accept={props.accept}
			/>
			<label htmlFor='file' className={`dropArea${dragover ? ' dragover' : ''}${props.uploading ? ' loading' : ''}`}>
				{!props.uploading && (
					<>
						<span>
							{props.type === 'file' && t('drag_and_drop_file')}
							{props.type === 'image' && t('drag_and_drop_image')}
							{props.type !== 'image' && props.type !== 'file' && t('drag_and_drop')}
							<span className='or'>{t('drag_and_drop_or')}</span>
						</span>
						<span className='Button secondary'>
							{props.type === 'file' && t('upload_file_button')}
							{props.type === 'image' && t('drag_and_drop_browse_image')}
							{props.type !== 'image' && props.type !== 'file' && t('drag_and_drop_browse')}
						</span>
					</>
				)}
				{props.uploading && <Spinner />}
			</label>
		</div>
	);
}
