import React, { useMemo } from 'react';
import styles from './HeroBanners.module.scss';
import PropTypes from 'prop-types';

const getBanners = (campaigns, now) => {
	if (!campaigns) return [];
	const nowTime = now ?? Date.now();

	let banners = campaigns
		.flatMap((campaign) => campaign?.banners?.map(banner => {
			let b = {
				...banner,
				campaignId: campaign.id,
				start: banner.start ? new Date(banner.start) : new Date(campaign.start),
				end: banner.end ? new Date(banner.end) : new Date(campaign.end)
			};

			if (banner.action.type === 'document') {
				b.document = campaign.documents.items.find(doc => doc.id === banner.action.doc);
			}

			return b;
		}))
		.filter(b => b)
		.filter(b => b.start.getTime() <= nowTime && b.end.getTime() >= nowTime)
		.sort((a, b) => b.weight - a.weight);

	return banners;
};

export default function HeroBanners({ setFilter, campaigns, locale, now, ...props }) {
	const secondaryBannersAmount = props.secondaryBannersAmount ?? 2;
	const banners = useMemo(() => getBanners(campaigns, now), [campaigns, now]);

	const setOrderFilter = id => setFilter(`campaigns.${id}`, 'include');

	const renderImage = banner => (
		<img
			src={banner.images?.[locale] ?? banner.image?.['nl']}
			alt={'promo banner'}
		/>
	);

	const renderFilterBanner = banner => (
		<div
			key={banner.id}
			onClick={() => setOrderFilter(banner.campaignId)}
			className={styles.pointerBanner}
		>
			{renderImage(banner)}
		</div>
	);

	const renderUrlBanner = banner => {
		if (banner.document.url.startsWith('http')) {
			return (
				<a
					key={banner.id}
					href={banner.document.url}
					target={'_blank'}
					rel={'noopener noreferrer'}
					className={styles.pointerBanner}
				>
					{renderImage(banner)}
				</a>
			);
		} else {
			return (
				<Link
					key={banner.id}
					to={banner.document.url}
					target={'_blank'}
					rel={'noopener noreferrer'}
					className={styles.pointerBanner}
				>
					{renderImage(banner)}
				</Link>
			)
		}
	};

	const renderDocBanner = banner => {
		if (banner.document.type === 'url') return renderUrlBanner(banner);
		const loc = banner.document?.file?.multilingual ? 'nl' : locale;
		const bannerTarget = banner?.document?.file?.[loc];
		return (
			<a
				key={banner.id}
				href={bannerTarget}
				target={'_blank'}
				rel={'noopener noreferrer'}
				className={styles.pointerBanner}
			>
				{renderImage(banner)}
			</a>
		);
	};

	const renderBasicBanner = banner => (
		<div key={banner.id} className={styles.banner}>
			{renderImage(banner)}
		</div>
	);

	const renderBanner = banner => {
		if (banner.action.type === 'filter') return renderFilterBanner(banner);
		if (banner.action.type === 'document') return renderDocBanner(banner);
		return renderBasicBanner(banner);
	};

	if (!banners || banners?.length === 0) return null;

	console.log({ banners, ifNotBanners: !banners || banners?.length === 0, secondaryBannersAmount });

	if (banners.length === 1) {
		return (
			<div className={styles.HeroBanners}>
				{renderBanner(banners[0])}
			</div>
		);
	} else if (banners.length === secondaryBannersAmount) {
		return (
			<div className={styles.HeroBanners}>
				<div className={styles.secondary}>
					{banners.map(renderBanner)}
				</div>
			</div>
		);
	}
	
	const [heroBanner, ...secondaryBanners] = banners.slice(0, secondaryBannersAmount + 1);

	return (
		<div className={styles.HeroBanners}>
			{renderBanner(heroBanner)}

			<div className={styles.secondary}>
				{secondaryBanners.map(renderBanner)}
			</div>
		</div>
	);
}

HeroBanners.propTypes = {
	setFilter: PropTypes.func,
	banners: PropTypes.array,
	locale: PropTypes.string.isRequired,
	secondaryBannersAmount: PropTypes.number
};
