import React, { useState } from 'react';
import './FileInput.scss';
import FileUpload from './../FileUpload';
import axios from 'axios';

export default function FileInput(props) {
	const {
		t,
		accept,
		type,
		baseUrl,
		value,
		prefix,
		preview
	} = props;

	const [isSaving, setIsSaving] = useState(false);

	const isUploading = props.upload ? props.isSaving : isSaving;
	const setIsUploading = props.upload ? props.setIsSaving : setIsSaving;

	const upload = async (files) => {
		if (props.upload) return props.upload(files);
		if (!baseUrl) return null;
		
		const file = files?.[0];
		if (!file) return null;

		setIsUploading(true);

		try {
			const fileData = new FormData();
			fileData.append('file', file);

			const res = await axios.post(`${baseUrl}/${file?.name}`, fileData, { withCredentials: true });
			props.onChange(`${prefix ?? ''}${res.data.url}`);
		} catch (error) {
			console.error(error);
			props.onChange(null);
		}
		
		setIsUploading(false);
	};

	return (
		<div className='FileInput'>
			{value && !preview &&
				<div className='file-path'>
					{value}
				</div>
			}
			{value && preview && preview(value)}
			{!props.disabled &&
				<FileUpload
					onDrop={upload}
					uploading={isUploading}
					type={type}
					accept={accept}
					t={t}
					disabled
				/>
			}
		</div>
	);
}
