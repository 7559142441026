import React, { useEffect, useState } from 'react';
import './DatePicker.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

export const getWeekNumber = (d) => {
	// Copy date so don't modify original
	d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
	// Set to nearest Thursday: current date + 4 - current day number
	// Make Sunday's day number 7
	d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
	// Get first day of year
	var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
	// Calculate full weeks to nearest Thursday
	var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
	// Return array of year and week number
	return [d.getUTCFullYear(), weekNo];
};

export const getActualDayOfWeek = date => {
	const americanWeekday = date.getDay();
	const dow = americanWeekday === 0 ? 7 : americanWeekday;
	return dow;
};

export const getWeekTuple = curr => {
	curr.setHours(0);
	curr.setMinutes(0);
	curr.setSeconds(0);
	curr.setMilliseconds(0);
	const first = curr.getDate() - getActualDayOfWeek(curr) + 1;
	const last = first + 6;

	const firstDay = (new Date(curr)).setDate(first);
	const lastDay = (new Date(curr)).setDate(last);
	return [new Date(firstDay), new Date(lastDay)];
};

export default function DatePicker(props) {
	const {
		translations,
		options,
		value,
		onChange,
		suggestions
	} = props;

	const [selectingIndex, setSelectingIndex] = useState(0);
	//const [hoverDate, setHoverDate] = useState(undefined);
	const [currentMonth, setCurrentMonth] = useState(undefined);

	useEffect(() => {
		if (props.currentMonth) {
			const date = props.currentMonth;
			const year = date.getFullYear();
			const month = date.getMonth();
			setCurrentMonth([year, month]);
		}
	}, [props.currentMonth]);

	useEffect(() => {
		if (currentMonth) return;

		const setMonth = date => {
			const year = date.getFullYear();
			const month = date.getMonth();
			setCurrentMonth([year, month]);
		};

		if (options?.single) {
			if (value) setMonth(value);
			return;
		} else {
			if (value?.[0]) {
				setMonth(value?.[0]);

				if (!value?.[1]) setSelectingIndex(1);
				return;
			} else if (value?.[1]) {
				setMonth(value?.[1]);

				setSelectingIndex(0);
				return;
			}
		}

		const now = new Date();
		setMonth(now);
	}, [value, setCurrentMonth, currentMonth]);

	const handleDayClick = date => {
		if (options?.single) {
			onChange(date);
			return;
		}

		if (selectingIndex === 0) {
			onChange([date, value?.[1]]);
			setSelectingIndex(1);
			return;
		} else {
			onChange([value?.[0], date]);
			setSelectingIndex(0);
			return;
		}
	};

	const renderSuggestions = () => {
		const setSuggestion = suggestion => {
			if (options?.single) {
				onChange(suggestion.value);
				setCurrentMonth([suggestion.value.getFullYear(), suggestion.value.getMonth()]);
				return;
			}

			onChange([ ...suggestion.value ]);
			const date = suggestion.value?.[0] ?? suggestion.value?.[1] ?? undefined;
			if (!date) return;
			setCurrentMonth([date.getFullYear(), date.getMonth()]);
		};

		return (
			<div className='suggestions'>
				{suggestions.map(suggestion => (
					<div
						key={suggestion.name}
						className='suggestion'
						onClick={() => setSuggestion(suggestion)}
					>
						{suggestion.name}
					</div>
				))}
			</div>
		);
	};

	const getPrettyDate = date => {
		if (!date) return null;
		const dayOfWeek = translations.days[getActualDayOfWeek(date) - 1];
		const day = date.getDate();
		const month = translations.months[date.getMonth()];
		const year = date.getFullYear();

		return `${dayOfWeek} ${day} ${month} ${year}`;
	};

	const renderSelectedDates = () => {
		const handleDateClick = (index) => {
			setSelectingIndex(index);
			if (index === 0) onChange([undefined, value?.[1]]);
			if (index === 1) onChange([value?.[0], undefined]);
		};

		if (options?.single) {
			return (
				<div className='selected-dates'>
					<span className={`start ${selectingIndex === 0 ? 'selected' : ''}`}>
						{getPrettyDate(value)}
					</span>
				</div>
			);
		}

		return (
			<div className='selected-dates'>
				<span className={`start ${selectingIndex === 0 ? 'selected' : ''}`} onClick={() => handleDateClick(0)}>
					{getPrettyDate(value?.[0])}
				</span>
				<span className='icon'><FontAwesomeIcon icon={faLongArrowAltRight} /></span>
				<span className={`end ${selectingIndex === 1 ? 'selected' : ''}`} onClick={() => handleDateClick(1)}>
					{getPrettyDate(value?.[1])}
				</span>
			</div>
		);
	};

	const getDaysOfMonth = (year, month) => {
		const date = new Date(year, month, 1);
		const dates = [];

		while (date.getMonth() === month) {
			dates.push(new Date(date));
			date.setDate(date.getDate() + 1);
		}

		return dates;
	};

	const getVisibleDaysByMonth = (year, month) => {
		const daysOfMonth = getDaysOfMonth(year, month);

		// Will always be the last day of the previous month
		// We only care about the month and year
		const aDateInPrevMonth = new Date((new Date(year, month, 1)).setDate(0));
		const daysOfPrevMonth = getDaysOfMonth(aDateInPrevMonth.getFullYear(), aDateInPrevMonth.getMonth());

		// Will always be a date in the next month
		// We only care about the month and year
		const aDateInNextMonth = new Date((new Date(year, month, 1)).setDate(33));
		const daysOfNextMonth = getDaysOfMonth(aDateInNextMonth.getFullYear(), aDateInNextMonth.getMonth());

		const firstDayOfMonth = new Date(year, month, 1);
		const lastDayOfMonth = new Date(year, month + 1, 0);

		return [
			...daysOfPrevMonth.slice(daysOfPrevMonth.length - getActualDayOfWeek(firstDayOfMonth) + 1, daysOfPrevMonth.length),
			...daysOfMonth,
			...daysOfNextMonth.slice(0, 7 - getActualDayOfWeek(lastDayOfMonth))
		];
	};

	const isSameDay = (a, b) => a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();

	const getHighlightedDateClasses = date => {
		if (!options?.highlightedDates) return '';

		return options?.highlightedDates.reduce((classes, item) => {
			const any = item.dates.some(d => isSameDay(d, date));
			if (any) return [ ...classes, item.className];
			return classes;
		}, []).join(' ');
	};

	const getClasses = (year, month, date) => {
		const classes = ['calendar__day'];

		if (date.getFullYear() === year && date.getMonth() === month) {
			classes.push('current-month');

			classes.push(getHighlightedDateClasses(date));
		} else {
			classes.push('off-month');
			if (options?.hideDaysOfPrevAndNextMonth) classes.push('hide');
		}

		const checkIfSelected = (value, className) => {
			if (value?.getFullYear() === date.getFullYear() &&
				value?.getMonth() === date.getMonth() &&
				value?.getDate() === date.getDate()) {
				classes.push('selected');
				classes.push(className);
			}
		};

		if (options?.single) {
			checkIfSelected(value, 'single');
		} else {
			checkIfSelected(value?.[0], 'start');
			checkIfSelected(value?.[1], 'end');

			if (
				!classes.includes('start') &&
				!classes.includes('end') &&
				value?.[0] &&
				value?.[1] &&
				value[0].getTime() < date.getTime() &&
				value[1].getTime() > date.getTime()
			) {
				classes.push('inbetween');
			}
		}

		return classes.join(' ');
	};

	const renderCalendar = (year, month) => {
		const monthName = translations.months[month];
		const visibleDays = getVisibleDaysByMonth(year, month);
		const [currYear, currMonth] = currentMonth;

		const handlePrevMonth = () => {
			if (currMonth !== month) return;
			const aDateInPrevMonth = new Date((new Date(currYear, currMonth, 1)).setDate(0));
			const year2 = aDateInPrevMonth.getFullYear();
			const month2 = aDateInPrevMonth.getMonth();
			setCurrentMonth([year2, month2]);
		};

		const handleNextMonth = () => {
			if (currMonth === month) return;
			const aDateInNextMonth = new Date((new Date(currYear, currMonth, 1)).setDate(33));
			const year2 = aDateInNextMonth.getFullYear();
			const month2 = aDateInNextMonth.getMonth();
			setCurrentMonth([year2, month2]);
		};

		const renderCalendarHeader = () => (
			<div className='calendar__header'>
				<div className='prev' onClick={() => handlePrevMonth()}>
					<FontAwesomeIcon icon={faChevronLeft} />
				</div>
				<div className='title'>
					<span className='month__name'>{monthName}</span>
					<span className='month__year'>{year}</span>
				</div>
				<div className='next' onClick={() => handleNextMonth()}>
					<FontAwesomeIcon icon={faChevronRight} />
				</div>
			</div>
		);

		const renderWeekNumbers = () => {
			const amountOfWeekNumbers = visibleDays.length / 7;

			const setWeek = date => {
				if (options?.single) return;
				const value = getWeekTuple(date);
				onChange(value);
			};

			return (
				<div className={`week-numbers ${options?.single ? 'single' : ''}`}>
					{[...Array(amountOfWeekNumbers).keys()].map(index => {
						const firstDayOfMonth = new Date(year, month, 1);
						const firstDayOfWeek = new Date(firstDayOfMonth.setDate(index * 7 + 1));
						const weekNumber = getWeekNumber(firstDayOfWeek)[1];

						return (
							<span key={index} onClick={() => setWeek(firstDayOfWeek)}>{weekNumber}</span>
						);
					})}
				</div>
			);
		};

		const renderDaysOfWeek = () => (
			<div className='days-of-week'>
				{translations.days.map(d => (
					<span key={d.slice(0, 2)}>{d.slice(0, 2)}</span>
				))}
			</div>
		);

		const renderCalendarDays = () => (
			<div className='calendar__days'>
				{visibleDays.map(date => {
					const offMonth = date.getMonth() !== month;
					if (offMonth && options?.hideDaysOfPrevAndNextMonth) {
						return (
							<span
								key={date.getTime()}
								className={getClasses(year, month, date)}
							/>
						);
					}

					return (
						<span
							key={date.getTime()}
							className={getClasses(year, month, date)}
							onClick={() => handleDayClick(date)}
							//onMouseEnter={() => setHoverDate(date)}
						>
							{date.getDate()}
						</span>
					);
				})}
			</div>
		);

		return (
			<div
				className={`calendar ${month !== currMonth ? 'secondary-month' : 'primary-month'}`}
				//onMouseLeave={() => setHoverDate(undefined)}
			>
				{renderCalendarHeader()}
				<div className='calendar__content'>
					{renderWeekNumbers()}
					{renderDaysOfWeek()}
					{renderCalendarDays()}
				</div>
			</div>
		);
	};

	const renderCalendars = () => {
		if (!currentMonth) return null;

		const year1 = currentMonth[0];
		const month1 = currentMonth[1];

		const aDateInNextMonth = new Date((new Date(year1, month1, 1)).setDate(33));
		const year2 = aDateInNextMonth.getFullYear();
		const month2 = aDateInNextMonth.getMonth();

		return (
			<div className='calendars'>
				{renderCalendar(year1, month1)}
				{!options?.singleMonth && renderCalendar(year2, month2)}
			</div>
		);
	};

	return (
		<div className='DatePicker'
			style={{
				'--suggestions-size': options?.showSuggestions ? '16rem' : '0',
				'--selected-size': options?.hideSelectedDates ? '0' : '4rem',
				'--show-week-numbers': options?.hideWeekNumbers ? '0' : '1'
			}}
		>
			{options?.showSuggestions && renderSuggestions()}
			{!options?.hideSelectedDates && renderSelectedDates()}
			{renderCalendars()}
		</div>
	);
}
