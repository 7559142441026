import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from './../Spinner';

function classList(classes) {
	return Object
		.entries(classes)
		.filter(entry => entry[1])
		.map(entry => entry[0])
		.join(' ');
}


function Button(props) {
	const {
		className,
		color,
		borderOnly,
		textOnly,
		showSpinner,
		children,
		icon,
		iconPosition,
		...restProps
	} = props;
	const colorString = props.color?.toString() ?? 'default';
	const classes = classList({
		'primary': props.primary,
		'secondary': !props.primary && (props.secondary === undefined || props.secondary),
		'has-spinner': props.showSpinner,
		'no-children': !props?.children
	});

	const Wrapper = !props.href ? (props) => <button {...props} /> : (props) => <a {...props} />;

	const iconLeft = props.icon && (props.iconPosition === 'left' || props.iconPosition === undefined) ? props.icon : null;
	const iconRight = props.icon && props.iconPosition === 'right' ? props.icon : null;

	return (
		<Wrapper
			className={`Button ${colorString} ${className ?? ''} ${classes}`}
			{...restProps}
		>
			<span className={'content'} key={'content'}>
				{iconLeft && <span key={'left'} className={'icon left'}>{iconLeft}</span>}
				{children && <span key={'text'}>{children}</span>}
				{iconRight && <span key={'right'} className={'icon right'}>{iconRight}</span>}
			</span>
			{showSpinner && <Spinner key={'spinner'} size='small' />}
		</Wrapper>
	);
};

Button.propTypes = {
	color: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	icon: PropTypes.any,
	iconPosition: PropTypes.string,
	href: PropTypes.string,
	showSpinner: PropTypes.bool,
	primary: PropTypes.bool,
	//secondary: PropTypes.bool
};

export default Button;
