import React, {useState} from 'react';
import './LanguageSelector.scss';
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function LanguageSelector(props) {
	const [isCollapsed, setCollapsed] = useState(true);
	return (
		<div className={'LanguageSelector' + (isCollapsed ? '': ' open') + (props.size === 'small' ? ' small' : '')}>
			<span
				key={props.language}
				className={'selected'}
				onClick={() => setCollapsed(!isCollapsed)}
			>
				{props.size !== 'small' && <FontAwesomeIcon icon={isCollapsed ? faCaretDown : faCaretUp}/>}
				{props.languages[props.language]?.[props.size ?? 'large'] ?? props.language}
			</span>

			{!isCollapsed && Object.entries(props.languages).map(([language, sizes]) => {
				if (language === props.language) return null;
				return (
					<span
						key={language}
						onClick={() => {
							props.onChange(language);
							setCollapsed(!isCollapsed);
						}}
					>
					{sizes[props.size ?? 'large'] ?? language}
				</span>
				);
			})}
		</div>
	);
}
