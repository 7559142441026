import React from 'react';
import './FlextArea.scss';

export default function FlextArea(props) {
	const {
		onChange,
		value,
		readOnly,
		...args
	} = props;

	// this dynamically changes the height of the box if there is more or less text
	const flexArea = (target) => {
		target.rows = 0;
		target.rows = Math.floor((target.scrollHeight - 10) / 24);
	};

	if (readOnly) {
		return (
			<div className={'FlextArea'} {...args}>
				{value}
			</div>
		);
	}

	return (
		<textarea
			className={'FlextArea'}
			value={value}
			onChange={({target}) => {
				flexArea(target);
				onChange(target.value);
			}}
			{...args}
		/>
	);
}
