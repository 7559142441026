import React, {useEffect, useRef, useState} from 'react';

const DateInputText = (props) => {
	const input = useRef(null)

	const [date, setDate] = useState('');
	const [displayDate, setDisplayDate] = useState(undefined);

	const formatDate = (date) => {
		if(!date) return '';
		const day = date.getDate().toString();
		const month = (date.getMonth() + 1).toString();
		const year = date.getFullYear();

		return `${day}/${month}/${year}`;
	};

	useEffect(() => {
		setDate(formatDate(props.date));
	}, [props.date]);

	useEffect(() => {
		if (!date) return;
		if (date === formatDate(props.date)) return;

		if ( /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date) ){
			const [day, month, year] = date.split('/');
			const beginDate = new Date(+year, +month - 1, +day);
			props.setDate(beginDate);
		}
	}, [date]);

	return (
		<div className={'DateInput'}>
			<label>
				<span>{props.label}</span>
				<input
					placeholder={props.placeholder}
					ref={input}
					type={'text'}
					value={displayDate ?? date}
					onChange={e => setDisplayDate(e.target.value)}
					onBlur={() => {
						setDate(displayDate)
						setDisplayDate(undefined)
					}}
					onKeyDown={e => {
						if(e.key === 'Enter' || e.keyCode === 13) {
							// @ts-ignore
							e.target.blur();
						}
					}}
				/>
			</label>
		</div>

	);
};

export default DateInputText;
