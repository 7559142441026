import React, {useState} from 'react';
import './ListFilters.scss';
import Input from "../Input";
import Autosuggest from "react-autosuggest";
import Highlight from "../Highlight";

export default function AutoSuggestFilter({ value, setValue, uniques, placeholder, disabled }) {
	const [suggestions, setSuggestions] = useState([]);

	const calculateSuggestions = (input) => {
		const matchingSuggestions = uniques.filter((item) => {
			return item.toLowerCase().includes(input.toLowerCase());
		});
		setSuggestions(matchingSuggestions);
	};

	const clearSuggestions = () => {
		setSuggestions([]);
	};

	const renderSuggestion = (suggestion) => {
		return <Highlight text={suggestion} string={value} />;
	};

	const onSuggestionSelected = (e, { suggestion }) => {
		e.preventDefault();
		setValue(suggestion);
	};

	const getSuggestionValue = (item) => {
		return item;
	};

	return (
		<div className='ListFilter AutoSuggestFilter'>
			<Autosuggest
				suggestions={suggestions}
				onSuggestionsFetchRequested={({ value }) => calculateSuggestions(value)}
				onSuggestionsClearRequested={clearSuggestions}
				renderSuggestion={renderSuggestion}
				getSuggestionValue={getSuggestionValue}
				inputProps={{
					value: value ?? '',
					onChange: (e) => setValue(e.target.value),
					placeholder,
					disabled
				}}
				onSuggestionSelected={onSuggestionSelected}
				highlightFirstSuggestion
				renderInputComponent={inputProps => <Input {...inputProps} />}
			/>
		</div>
	);
}
