import React, {useEffect, useState} from 'react';
import Input from "../Input";

const SlowInput = React.forwardRef((props, ref) => {
	const {
		onChange,
		value,
		...restProps
	} = props;

	const [localValue, setLocalValue] = useState('');
	const [isEditing, setEditing] = useState(false);

	useEffect(() => {
		if (!isEditing){
			setLocalValue(value);
		}
	}, [value, isEditing]);

	return (
		<Input
			{...restProps}
			value={isEditing ? localValue : value}
			onChange={e => setLocalValue(e.target.value)}
			onFocus={_ => setEditing(true)}
			onBlur={e => {
				onChange(e);
				setEditing(false);
			}}
			ref={ref}
		/>
	);
});

export default SlowInput;
