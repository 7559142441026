import React from 'react';
import BackButton from './../BackButton';
import CharlesTitle from './../CharlesTitle';
import './CampaignDetail.scss';

export default function CampaignDetail(props) {
	const {
		campaign,
		locale
	} = props;

	const renderDocument = (doc, locale, campaign) => {
		const start = doc.start ? new Date(doc.start) : new Date(campaign.start);
		const end = doc.end ? new Date(doc.end) : new Date(campaign.end);
		if (start > Date.now() || end < Date.now()) return null;

		let content = null;

		if (doc?.type === 'file') {
			const url = doc?.file?.multilingual ? doc?.file?.nl : doc?.file?.[locale];
			content = (
				<a
					href={url}
					target={'_blank'}
					rel={'noopener noreferrer'}
				>
					{doc?.description?.[locale]}
				</a>
			);
		} else if (doc?.type === 'url') {
			content = (
				<a
					href={doc?.url}
					target={'_blank'}
					rel={'noopener noreferrer'}
				>
					{doc?.description?.[locale]}
				</a>
			);
		}
		
		return (
			<div key={doc?.id} className='document'>
				{content}
			</div>
		);
	};

	const renderDocumentsInLanguage = (locale) => (
		<div key={locale} className='documents__language'>
			{campaign?.documents?.items?.map((doc) => renderDocument(doc, locale, campaign))}
		</div>
	);

	const renderDocuments = () => {
		const secondaryLocale = locale === 'nl' ? 'fr' : 'nl';

		return (
			<div className='documents'>
				{renderDocumentsInLanguage(locale)}
				{renderDocumentsInLanguage(secondaryLocale)}
			</div>
		);
	};

	return (
		<div className='CampaignDetail'>
			<div className='container'>
				<BackButton to={props?.to}>
					{props?.textBack}
				</BackButton>
				<CharlesTitle alt>{campaign?.name?.[locale ?? 'nl']}</CharlesTitle>
				<p>{campaign?.documents?.copy?.[locale ?? 'nl']}</p>
				{renderDocuments()}
			</div>
		</div>
	);
}
