import React, { useMemo } from 'react';
import CharlesTitle from './../CharlesTitle';
import './CampaignsOverview.scss';

export default function CampaignsOverview(props) {
	const { campaigns: unfilteredCampaigns, locale } = props;
	const campaigns = useMemo(() => 
		unfilteredCampaigns
			.filter(item => item.documents?.cover)
			.sort((a, b) => {
				const aWeight = a?.documents?.cover?.weight ?? 0;
				const bWeight = b?.documents?.cover?.weight ?? 0;
				const weightDelta = bWeight - aWeight;
				if (weightDelta === 0) return (new Date(b.start)).getTime() - (new Date(a.start)).getTime();
				return weightDelta;
			}),
		[unfilteredCampaigns]
	);

	const renderItem = item => (
		<div className='campaign__item' onClick={() => props.handleClick(item)}>
			<img src={item.documents.cover?.[locale ?? 'nl'] ?? item.documents.cover?.nl} alt={item.name?.[locale ?? 'nl']} />
			<h3>{item.name?.[locale ?? 'nl']}</h3>
		</div>
	);

	console.log({ props });

	return (
		<div className='CampaignsOverview'>
			<div className='container'>
				<CharlesTitle alt>Folders & Promoties</CharlesTitle>
				<div className='campaigns'>
					{campaigns.map(renderItem)}
				</div>
			</div>
		</div>
	);
}
